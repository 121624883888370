import { isDevelopment } from "@biblioteksentralen/js-utils";
import { Site } from "@libry-content/types";
import { LanguageCode, LocalizedField, DEFAULT_LANGUAGE_CODE } from "@libry-content/localization";
import type { SanityClient } from "@sanity/client";
import { filters } from "./filters";
import { platform } from "./platform";

export interface AdminSite {
  _id: "sites.admin";
  subdomain: "admin";
  name: LocalizedField<string>;
  languages: LanguageCode[];
}

type SiteI = Pick<Site, "customDomain" | "subdomain" | "_id"> | AdminSite;

export const ADMIN_SITE: AdminSite = {
  _id: "sites.admin",
  name: {
    nb: "Adminsida",
  },
  subdomain: "admin",
  languages: [DEFAULT_LANGUAGE_CODE],
};

export const isAdminSite = (site: SiteI | undefined): site is AdminSite => site?._id === ADMIN_SITE._id;

export const isNormalSite = (site: SiteI | undefined): site is Site =>
  site?._id !== undefined && site._id !== ADMIN_SITE._id;

/**
 * Find Site from the value of a host header.
 *
 * @param host Value of the [Host header](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Host)
 * @param client SanityClient
 * @returns Instance of Site or AdminSite, or null if unknown site.
 */
export const findSiteFromHost = async (host: string, client: SanityClient): Promise<Site | AdminSite | null> => {
  if (host.endsWith(platform.domain)) {
    const subdomain = host.split(".")[0];

    if (subdomain === "admin") {
      return ADMIN_SITE;
    }
    return await client.fetch(`*[ _type == "site" && subdomain == $subdomain && ${filters.isNotDraft}][0]`, {
      subdomain,
    });
  }

  return await client.fetch(`*[ _type == "site" && customDomain == $domain && ${filters.isNotDraft}][0]`, {
    domain: host,
  });
};

/**
 * Get the preferred domain for a given Site.
 *
 * @returns Domain name without http(s) prefix. For localhost, the port number is included.
 */
export const getSiteDomain = (site: SiteI): string => {
  if (isAdminSite(site)) {
    return `admin.${platform.domain}`;
  }
  return site.customDomain || `${site.subdomain}.${platform.domain}`;
};

/**
 * Get the base URL for a given site.
 *
 * @param site - Site instance.
 * @returns URL
 */
export const getSiteUrl = (site: SiteI): string => `${isDevelopment() ? "http" : "https"}://${getSiteDomain(site)}`;
