import { useMount } from "react-use";
import { init, push } from "@socialgouv/matomo-next";
import { isDevelopment } from "@biblioteksentralen/js-utils";
import { isProductionDataset } from "../currentDataset";

export function MatomoSetup() {
  useMount(() => {
    // TODO sette opp logging fra localhost og staging?
    if (!isDevelopment() && isProductionDataset)
      init({
        url: "https://bibliotek.matomo.cloud",
        siteId: "1",
      });
  });

  return null;
}

export function logMamotoEvent(event: string, extraData?: Record<string, string | number>) {
  try {
    // Oversetter ekstra-fields til data som kan sendes til Matomo. Dette er jo en hacky løsning, men matomo og plausible logger events litt forskjellig, så satser på at dette funker til vi får bestemt oss for plausible vs Matomo https://matomo.org/docs/event-tracking/#tracking-events
    const extraFields = extraData ? Object.entries(extraData).map((entry) => entry[1]) : [];
    const logEntry = ["trackEvent", "event", event, ...extraFields.slice(0, 2)];
    push(logEntry);
  } catch (e) {
    console.error(e);
  }
}
